import React, { useEffect } from "react";
import { fuseAnalyticsClient } from "@appfire/fuse-analytics-client";
import { useApplicationContext } from "../screens/Providers/ApplicationContextProvider";

interface FuseAnalyticsProps {
  children: React.ReactNode;
}

export function FuseAnalytics({ children }: FuseAnalyticsProps) {
  const { addonKey } = useApplicationContext();
  useEffect(() => {
    fuseAnalyticsClient("Jira", {
      lite: true,
      liteConfig: {
        amplitudeApiKey: process.env.AMPLITUDE_API_KEY, // generated api key from amplitude
        appKey: addonKey,
      },
      fingerprintAccessKey: process.env.FINGERPRINT_PUBLIC_API_KEY,
    });
  }, [addonKey]);

  return <>{children}</>;
}
